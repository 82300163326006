body{
    margin: 0 !important;
}

.about-r{
    font-family: 'Courier New', monospace !important;
    height: auto;
    display: flex;
    flex-direction: column;
}

.about-title-r{
    width: 15% !important;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: 17px;
    align-items: stretch;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    max-width: 15% !important;
    inset: -0px auto auto 0px !important;
    width: 110px !important;
    min-width: 100px;
    top: 0 !important;
    border-right: 3px solid black;
    border-top: 2px solid;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 35px;
    border-bottom: 4px solid;
}

.about-pages-r {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 70px;
}

.scrollmagic-pin-spacer{
    margin: 0% 0 0 !important;
}

.headroom-wrapper{
    height: 0px !important;
}

@media screen and (min-width: 1550px) {
    .about-pages-r {
        margin-top: 72px;
    }
}

@media screen and (min-width: 1650px) {
    .about-pages-r {
        margin-top: 78px;
    }
}

@media screen and (min-width: 1750px) {
    .about-pages-r {
        margin-top: 83px;
    }
}