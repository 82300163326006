@font-face {
    font-family: BebasNeue-Regular;
    src: url('../resources/fonts/BebasNeue-Regular.ttf');
}

/* The Modal (background) */
.modalA {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 500;
    /* Sit on top */
    /* padding-top: 80px; */
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    padding-top: 15px;
    padding-bottom: 15px;
}

/* Modal Content */
.modal-contentA {
    background-color: #fefefe;
    margin: auto;
    /* padding: 20px; */
    border: 1px solid #888;
    width: 50%;
    max-width: 700px;
    /* margin-bottom: 30px; */
    border: 1px solid black;
}

/* The Close Button */
.closeA {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.infoAlbumModal {
    font-family: BebasNeue-Regular;
    font-size: 29px;
    margin-top: 10px;
}

.containerInfoAlbumModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
}

.containerImgAlbumModal {
    border: 2px solid black;
    /* padding: 15px; */
    margin-bottom: 20px;
    /* margin-top: 30px; */
}

.textTitleAlbumModal {
    font-family: BebasNeue-Regular;
    font-size: 22px;
}

.artistAlbumModal {
    /* margin-left: 20px; */
    font-size: 18px;
    /* word-break: break-all; */
    max-width: 210px;
    text-align: center;
}

.titleAlbumModal {
    /* margin-left: 20px; */
    font-size: 18px;
    max-width: 210px;
    text-align: center;
}

.containerTitleBlockModal {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

.containerAlbumBlockModal {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

.containerTrackModal {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.containerAllInfoModalAlbum {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 3%;
    font-family: 'Courier New', Courier, monospace;
}

.containerAllElementsTracks {
    margin-top: 20px;
    background-color: black;
    color: white;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.textTracksAlbumModal {
    font-family: BebasNeue-Regular;
    font-size: 25px;
    /* border-top: 1px solid black; */
    text-align: center;
    padding-top: 20px;
}

.buttonCloseModalA {
    border: none;
    background-color: white;
    color: black;
}

.containerButtonModalAlbum {
    width: 100%;
}

.buttonCloseModalA {
    margin-right: 1% !important;
    margin-top: 1% !important;
    background-color: black;
    border-radius: 50%;
    min-width: 46px;
    color: white;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.ContainerVinylLoaderModal {
    border: 1px solid black;
    padding: 140px;
    /* max-width: 650px; */
}

.containerAnimVinylLoadAppModal {
    width: 65%;
    margin: 0 auto;
}

.releaseDataAlbumModal {
    /* margin-left: 15px; */
    font-size: 17px;
}




@media screen and (max-width: 900px) {
    .modal-contentA {
        width: 70%;
    }

    .ContainerVinylLoaderModal {
        border: 1px solid black;
        padding: 100px;
        /* max-width: 650px; */
    }

    .containerTitleBlockModal {
        margin-bottom: 10px;
    }
}


@media screen and (max-width: 800px) {
    .modal-contentA {
        width: 80%;
    }

    .ContainerVinylLoaderModal {
        border: 1px solid black;
        padding: 80px;
        /* max-width: 650px; */
    }

    .buttonCloseModalA {
        margin-right: 1% !important;
        margin-top: 1% !important;
        background-color: black;
        border-radius: 50%;
        min-width: 46px;
        color: white;
    }
}

@media screen and (max-width: 600px) {
    .modal-contentA {
        width: 95%;
    }

    .ContainerVinylLoaderModal {
        border: 1px solid black;
        padding: 60px;
        /* max-width: 650px; */
    }

    .titleAlbumModal {
        font-size: 16px;
    }

    .artistAlbumModal {
        font-size: 16px;
    }

    .releaseDataAlbumModal {
        font-size: 16px;
    }

    .marginReleaseDate {
        margin-top: 10px;
    }

    .textTitleAlbumModal {
        font-size: 19px;
    }

    .buttonCloseModalA {
        margin-right: 1% !important;
        margin-top: 1% !important;
        background-color: white;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        /* color: white; */
        color: black;
        font-size: 28px;
    }
}