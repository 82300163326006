.containerForm{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-areas: "a b c";
    margin-top: 30px;
    /* margin-top: 11%; */
}
.ant-form {
    grid-area: b;
    margin:auto;
}
.buttonGetAlbum {
    /* margin:auto; */
    /* width: 40%; */
    margin-left: auto;
    margin-right: auto;
}

.divContainerBottomGetAlbum {
    font-family: 'Courier New', sans-serif !important;
    grid-area: b;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    /* padding-right: 35px; */
    margin-bottom: 20px;
    width: 65%;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
}
.container{
    width: 100%;
}

.ant-btn > span {
    display: flex;
    justify-content: center;
}