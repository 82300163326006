@font-face {
    font-family: BebasNeue-Regular;
    src: url('../resources/fonts/BebasNeue-Regular.ttf');
}

.containerAboutUs {
    font-size: 22px;
    font-family: 'Courier New', Courier, monospace;
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 70px;
    margin-bottom: 70px;
    justify-content: center;
    align-items: flex-start;
    background-image: url('../resources/sfondoAboutUsPc.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 80px;
}

.containerTitleAboutUs {
    font-size: 28px;
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 30px;
    font-family: BebasNeue-Regular;
    border: 1px solid black;
    width: 85%;
    height: 100%;
}

.aboutPageAboutUs {
    margin-top: 0px;
    width: 100%;
}

.txtAllArticle {
    padding: 30px;
    width: 65%;
    background-color: white;
    border: 1px solid black;
    max-width: 530px;
}

.txtTitleAboutUs {
    font-family: BebasNeue-Regular;
    font-size: 35px;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 20px;
}

.backFromAboutTitle{
    width: 60px;
    margin-top: 19px;
    animation: blinker 1.3s linear infinite;
    transform: rotate(90deg);
}
  
  @keyframes blinker {
    40% {
      opacity: 0;
    }
  }

  .txtAllArticleRes {
    padding: 10px;
    width: 65%;
    background-color: white;
    border: 1px solid black;
    max-width: 530px;
    margin-bottom: 40px;
}

.txtTitleAboutUsRes {
    font-family: BebasNeue-Regular;
    font-size: 27px;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid rgb(1, 1, 1);
    padding: 10px;
    padding-bottom: 0px;
    margin-bottom: 30px;
}

.containerAboutUsRes {
    font-size: 22px;
    font-family: 'Courier New', Courier, monospace;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../resources/sfondoAboutUsMobile.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
}

.backFromAboutTitleRes{
    width: 45px;
    margin-top: 19px;
    animation: blinker 1.3s linear infinite;
    transform: rotate(90deg);
}

@media screen and (max-width: 550px) {
    .txtTitleAboutUsRes {
        font-family: BebasNeue-Regular;
        font-size: 32px;
        color: rgb(3, 3, 3);
        border-bottom: 1px solid rgb(0, 0, 0);
        padding: 10px;
        padding-bottom: 0px;
        margin-bottom: 30px;
    }
    .containerAboutUsRes {
        font-size: 16px;
    }
}

@media screen and (max-width: 700px) {
    .containerAboutUsRes {
        font-size: 20px;
    }
}

@media screen and (max-width: 450px) {
    .txtAllArticleRes {
        font-size: 15px;
    }
}