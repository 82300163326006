.MuiGrid-spacing-xs-8 {
    width: calc(100% + 64px);
    margin: 0px !important;
}
.MuiGrid-spacing-xs-8 > .MuiGrid-item{
    padding: 3px !important;
}

@media only screen and (max-width: 550px) {
    .MuiGrid-spacing-xs-8 > .MuiGrid-item {
        
    }
}

.noCoverFoundText{
    font-size: 20px;
}