img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: 330px;
  width: 330px;
}

@media only screen and (max-width: 1600px) {
  img.responsive-img,
  video.responsive-video {
    height: 310px;
    width: 310px;
  }
}

@media only screen and (max-width: 1500px) {
  img.responsive-img,
  video.responsive-video {
    height: 290px;
    width: 290px;
  }
}

@media only screen and (max-width: 1400px) {
  img.responsive-img,
  video.responsive-video {
    height: 270px;
    width: 270px;
  }
}

@media only screen and (max-width: 1200px) {
  img.responsive-img,
  video.responsive-video {
    height: 240px;
    width: 240px;
  }
}

@media only screen and (max-width: 1100px) {
  img.responsive-img,
  video.responsive-video {
    height: 210px;
    width: 210px;
  }
}

@media only screen and (max-width: 900px) {
  img.responsive-img,
  video.responsive-video {
    height: 200px;
    width: 200px;
  }
}

@media only screen and (max-width: 650px) {
  img.responsive-img,
  video.responsive-video {
    height: 180px;
    width: 180px;
  }
}

@media only screen and (max-width: 550px) {
    .flow-text {
      font-size:3.5vw;

    }
    img.responsive-img,
    video.responsive-video {
      max-width: 100%;
      height: 170px;
      width: 170px;
    }
    .imgAlbumForInfoModal{
      height: 213px !important;
      width: 213px !important;
    }
    .makeStyles-paper-2 {
      color: rgba(0, 0, 0, 0.54);
      text-align: center;
      padding: 0px !important;
  }
}