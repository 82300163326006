

.myModal-infosite {
  display: block;
  /* o 'none' se vuoi che sia nascosta di default */
  position: fixed;
  z-index: 1000;
  /* Assicurati che sia sopra altri elementi */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  /* Sfondo grigio trasparente */
}

.Mmodal-content-infosite {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 2%;
  border: 1px solid #888;
  font-family: monospace;
  width: 45%;
  /* padding: 3rem; */
  position: relative;
  border-radius: 39px;
  border: 3px solid black;
  box-shadow: 0 4px 8px 0 rgb;
}

.Mmodal-content-infosite h3 {
  background-color: black;
  color: white;
  padding: 1;
  border-radius: 20px;
  padding: 1%;
  padding-left: 2%;
}

.linkInfoCoverArtArchiveModal {
  color: black;
}

.buttonModalInfoSiteClosingModal {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  border: 1px solid black;
  padding: 1%;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 15px;
}

.buttonModalInfoSiteClosingModal:hover {
  color: white;
  background-color: black;
  transition: background-color 0.5s ease;
}

.div-buttonModalInfoSiteClosingModal {
  text-align: right;
}


.containerButtonModalAlbum-infoalbum {
  position: absolute;
  top: 0;
  right: 0;
  /* Posiziona il bottone in alto a destra */
  padding: 10px;
}

.closeA-infoalbum,
.buttonCloseModalA-infoalbum {
  font-size: 30px;
  /* Aumenta la dimensione se necessario */
  border: none;
  background-color: transparent;
  cursor: pointer;
}


@media screen and (max-width: 900px) {
  .Mmodal-content-infosite {
    background-color: #fefefe;
    margin: 10% auto;
    /* padding: 3%; */
    border: 1px solid #888;
    width: 80%;
    padding: 3%;
    font-family: monospace;
    font-size: 14px;
    position: relative;
    border-radius: 6%;
    border: 3px solid black;
    box-shadow: 0 4px 8px 0 rgb;
  }
}



@media screen and (max-width: 600px) {
  .Mmodal-content-infosite {
    background-color: #fefefe;
    margin: 10% auto;
    /* padding: 3%; */
    border: 1px solid #888;
    width: 90%;
    padding: 3%;
    font-family: monospace;
    font-size: 12px;
    position: relative;
    border-radius: 6%;
    border: 3px solid black;
    box-shadow: 0 4px 8px 0 rgb;
  }

  .Mmodal-content-infosite h3 {
    font-size: 17px;
    padding-left: 3%;
  }
}

@media screen and (max-width: 400px) {
  .Mmodal-content-infosite {
    background-color: #fefefe;
    margin: 11% auto;
    /* padding: 3%; */
    border: 1px solid #888;
    padding: 2%;
    font-family: monospace;
    font-size: 10px;
    position: relative;
    border-radius: 17px;
    border: 2px solid black;
    box-shadow: 0 4px 8px 0 rgb;
  }

  .Mmodal-content-infosite h3 {
    font-size: 17px;
    padding-left: 4%;
  }
}