@font-face {
  font-family: BebasNeue-Regular;
  src: url('../resources/fonts/BebasNeue-Regular.ttf');
}

@font-face {
  font-family: AlfaSlabOne-Regular;
  src: url('../resources/fonts/Monoton-Regular.ttf');
}

.ant-menu {
  line-height: inherit !important;
}

.ant-menu-inline {
  border-right: 0px solid #f0f0f0 !important;
}

.ant-menu-submenu-title {
  padding-left: 24px;
  font-size: 30px;
  height: 90px !important;
  font-family: 'Courier New', sans-serif !important;
  border-radius: 20px;
}

.containerAnimDot {
  width: 200px;
}

.ant-menu {
  border-radius: 30px;
  grid-area: b;
}

.containerAnimCircle {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.containerAnimDiscoLoader {
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
}

.moreAlbum {
  width: 15%;
  max-width: 160px;
  margin: 0 auto;
  display: grid;
  margin-bottom: 30px;
  margin-top: 30px !important;
  font-family: 'Courier New', sans-serif !important;
  cursor: pointer;
}

.containerNavIcons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0px;
}

.contRel {
  padding-top: 40px;
  padding-bottom: 30px;
}

.titleDecade {
  margin-bottom: 30px;
  font-size: 39px;
  margin-left: 1%;
  margin-right: 1%;
  background-color: black;
  color: white;
  padding: 12px;
  padding-left: 17px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.showNoCoverButton {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: solid 1px black;
  background-color: white;
  margin-top: 3%;
  cursor: pointer;
}

.infoNoCoverDiv {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 335px;
}

.noCoverDesk {
  border-radius: 20px;
}

.infoNoCoverDivRes {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableNoCoverUnique {
  margin-top: 30px;
  margin-right: 10px;
}

.ant-table-tbody>tr>td {
  border: solid 0.2px black;
}

.ant-table-thead>tr>th {
  font-weight: bold !important;
  font-size: 17px;
  background-color: black;
  color: white;
}

.itemNavHome {
  width: 70px;
}

.MuiGrid-item {
  white-space: normal;
  word-wrap: break-word;
}

/*---------------------menu responsive------- */
ol,
ul {
  padding-left: 0px;
}

.menuItem {
  display: flex;
  width: 100%;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 27px;
}

.menuItem:hover {
  text-decoration: underline;
}

.hamburger {
  z-index: 100;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.closeIcon {
  display: none;
}

.menu {
  position: fixed;
  transform: translateY(-100%);
  transition: transform 0.2s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  color: black;
  list-style: none;
  height: 100%;
}

.showMenu {
  transform: translateY(0);
}

.itemMenuResponsive {
  background-color: #000000;
  color: white;
  font-size: 30px;
  font-family: BebasNeue-Regular;
}

.containerIconCloseAndArticleText {
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.itemMenuResponsiveVibez {
  background-color: #000000;
  color: white;
  font-size: 30px;
  font-family: BebasNeue-Regular;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.linkAboutUs {
  display: none;
}

.ctvHome {
  width: 100%;
  padding-left: 3px;
  padding-right: 3px;
}

.ctvhomecontainer {
  border-bottom: 0.8px solid black;
}

.tableNoCover-resText {
  text-align: center;
  font-family: BebasNeue-Regular;
  font-size: 25px;
}

.infoTopPageAlbums {
  font-weight: 800;
  font-size: 35px;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 30px;
  font-family: BebasNeue-Regular;
}

.infoDateTodayRes {
  font-weight: 800;
  font-size: 30px;
  padding-top: 9px;
  text-align: center;
  font-family: BebasNeue-Regular;
}

.nocoverButtonRes {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: 1px solid rgb(0, 0, 0);
}

.iconArrowDown {
  width: 40px;
  height: 30px;
  margin-top: 5px;
  transition-duration: 0.5s;
  margin: auto;
}

.iconArrowRotate:active {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}



#singleAlbumGrid {
  transition: transform .1s;
  margin: 0 auto;
}

#singleAlbumGrid:hover {
  transform: scale(1.1);
}


#containerTopPage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
}

.container-div-form-day {
  margin-right: 8%;
  font-size: 50px !important;
  font-family: BebasNeue-Regular;
}


#complex-form .ant-picker {
  width: 80%;
  height: 100%;
  padding: 10px 11px 10px;
}

#complex-form .ant-form-item-control {
  width: 150px;
  display: flex;
  justify-content: center;
}

#complex-form .ant-form-item-label>label {
  font-size: 55px !important;
  font-family: BebasNeue-Regular;
  height: 100%;
}

.ant-form-inline {
  display: flex;
  flex-wrap: inherit;
}

[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  margin: auto;
}

.container-all-form-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-form-inline .ant-form-item {
  margin-right: 0px;
  margin-bottom: 0;
}

.contaier-text-forom {
  margin-top: 2px;
  font-family: BebasNeue-Regular;
  font-weight: 800;
  text-wrap: nowrap;
  line-height: 0.8;
  color: black;
  margin-bottom: 10px;
}

#complex-form .ant-btn {
  padding: 10px 30px;
  font-size: 16px;
  height: 100%;
  background-color: black;
  color: white;
  border-color: #000000;
}

#complex-form .ant-btn:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
  display: none;
}

.ant-picker-month-btn {
  pointer-events: none;
}

.ant-picker-header-super-next-btn {
  pointer-events: none;
  display: none;
}

.ant-picker-header-super-prev-btn {
  pointer-events: none;
  display: none;
}

.ant-picker-today-btn {
  color: black;
}

#complex-form_startYear {
  font-size: 17px;
}

.container-top-content-2 {
  margin-right: 5%;
  padding-left: 3%;
  display: flex;
  align-items: center;
}

.container-top-content-1-text {
  font-size: 17px;
  padding-right: 15px;
}

.container-top-content-2-text {
  font-size: 17px;
  font-size: 17px;
  width: 100%;
  text-align: center;
}

.container-top-content-3 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 2px solid;
  font-family: BebasNeue-Regular;
  padding-left: 30px;
  font-size: 45px;
  padding-right: 30px;
  background-color: black;
  color: white;
  height: 153px;
  border-radius: 50%;
}

@media screen and (max-width: 900px) {
  #containerTopPage {
    margin-top: 0px;
  }

  .contaier-text-forom {
    font-size: 35px;
    margin-top: 1%;
    font-weight: 900;
    letter-spacing: 0px;
  }

  .menuItem {
    display: flex;
    width: 100%;
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
    font-size: 27px;
    align-items: flex-end;
  }

  .container-all-form-component {
    margin-top: 25px;
    margin-bottom: 25px;
  }

}

.selected-decadi {
  background-color: black;
  color: white !important;
  padding-left: 20px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
}

.containerAnimDisco svg {
  width: 60% !important;
  height: 60% !important;
}

.li-items-menu-responsive {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  margin-bottom: 9px;
}


@media (min-width: 901px) {
  .containerAnim svg {
    width: 60% !important;
    height: 60% !important;
    transform: translate3d(77%, 0px, 0px) !important;
  }
}

@media (max-width: 1550px) {
  .text-shadow {
    left: 16px !important;
  }
}

@media (max-width: 1400px) {
  .text-shadow {
    left: 14px !important;
  }
}

@media (max-width: 1100px) {
  .text-shadow {
    left: 12px !important;
  }
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-menu {
  background: #ffffff;
  font-size: 1.15em;
}

.bm-item-list {
  color: #ffffff;
}

.menu-item {
  display: block;
  color: #000000;
  text-decoration: none;
}

.bm-menu {
  width: 100%;
  height: 100%;
  border-right: 3px solid black;
  border-bottom: 3px solid black;
  max-width: 350px;
}

.bm-menu-wrap {
  width: 25% !important;
  height: 70% !important;
  margin-top: 2% !important;
}

.bm-overlay {
  background: none !important;
  display: none;
}


.bm-burger-button {
  display: none !important;
}

.BurgherMenu-menu {
  position: absolute;
  right: 1%;
  margin: auto;
  margin-top: 0px;
  background-color: black;
}

.img-hamburgher-menu {
  width: 37%;
}

.BurgherMenu-menu {
  position: absolute;
  right: 0.4%;
  display: flex;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: black;
  justify-content: flex-end;
  z-index: 0;
  flex-wrap: nowrap;
  align-content: center;
}

.div-img-hamburgher-menu {
  width: 100%;
}

.container-logo-ori-navbar-right {
  right: 0.1rem;
  background-color: black;
  z-index: 100;
  /* border-top-left-radius: 50px; */
  /* border-bottom-left-radius: 50px; */
  font-family: BebasNeue-Regular;
  /* padding-right: 0px; */
  display: flex;
  border-left: 1px solid white;
  align-items: center;
}

@keyframes blinker {
  40% {
    opacity: 0;
  }
}

.img-hamburgher-arrow {
  animation: blinker 1.3s linear infinite;
  width: 30%;
}

.img-loro-ori-text-dkt {
  width: 294px;
  height: 23px;
  margin-left: 21px;
  margin-right: 21px;
}

.div-headroombar-right {
  display: flex;
  justify-content: center
}

.rotd-text-top-page {
  font-family: BebasNeue-Regular;
  width: 100%;
  height: 100%;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9.3vw;
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 0.8;
}

.container-text-rotd {
  position: relative;
  /* Contenitore di posizionamento relativo per il posizionamento assoluto del testo */
  width: 100%;
  /* Larghezza al 100% del viewport */
  display: flex;
  justify-content: center;
  /* Centra il contenuto orizzontalmente */
  align-items: center;
  /* Centra il contenuto verticalmente */
  font-family: BebasNeue-Regular;
  /* Usa il font specificato */
  font-size: 18.3vw;
  /* Dimensione del font basata sul 9.3% della larghezza del viewport */
  color: rgb(255, 255, 255);
  /* Colore del testo */
  text-align: center;
  /* Allinea il testo al centro */
  margin: 0;
  /* Rimuove i margini */
  padding: 0;
  /* Rimuove il padding */
  box-sizing: border-box;
  /* Include padding e bordi nel calcolo della larghezza/altezza */
  line-height: 0.8;
  /* Line-height ridotto per diminuire gli spazi verticali */

  -webkit-text-stroke-width: 2px;
  /* Larghezza della traccia del testo per WebKit/Blink browsers */
  -webkit-text-stroke-color: rgb(0, 0, 0);
  /* Colore della traccia del testo per WebKit/Blink browsers */
  text-stroke-width: 2px;
  /* Larghezza della traccia del testo standard */
  text-stroke-color: rgb(0, 0, 0);
  /* Colore della traccia del testo standard */
  right: 9px;
}

.text-shadow {
  position: absolute;
  color: rgb(0, 0, 0);
  top: 6px;
  left: 20px;
  z-index: 1;
}


.headroom-wrapper {
  max-height: 86px !important;
}

/* recordoftheday scritta responsive per mobile */
.container-text-rotd-res {
  position: relative;
  /* Contenitore di posizionamento relativo per il posizionamento assoluto del testo */
  width: 100%;
  /* Larghezza al 100% del viewport */
  display: flex;
  justify-content: center;
  /* Centra il contenuto orizzontalmente */
  align-items: center;
  /* Centra il contenuto verticalmente */
  font-family: BebasNeue-Regular;
  /* Usa il font specificato */
  font-size: 14.3vw;
  /* Dimensione del font basata sul 9.3% della larghezza del viewport */
  color: rgb(255, 255, 255);
  /* Colore del testo */
  text-align: center;
  /* Allinea il testo al centro */
  margin: 0;
  /* Rimuove i margini */
  padding: 0;
  /* Rimuove il padding */
  box-sizing: border-box;
  /* Include padding e bordi nel calcolo della larghezza/altezza */
  line-height: 0.8;
  /* Line-height ridotto per diminuire gli spazi verticali */

  -webkit-text-stroke-width: 2px;
  /* Larghezza della traccia del testo per WebKit/Blink browsers */
  -webkit-text-stroke-color: rgb(0, 0, 0);
  /* Colore della traccia del testo per WebKit/Blink browsers */
  text-stroke-width: 2px;
  /* Larghezza della traccia del testo standard */
  text-stroke-color: rgb(0, 0, 0);
  /* Colore della traccia del testo standard */
  right: 9px;
}

.text-shadow-res {
  position: absolute;
  color: rgb(0, 0, 0);
  top: 6px;
  left: 20px;
  z-index: 1;
}


.div-b-menu {
  position: fixed;
  left: -300px;
  top: 0;
  width: 300px;
  height: 530px !important;
  background-color: #ffffff;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  border-right: 18px solid black;
  border-bottom: 4px solid black;
  top: auto;
  margin-top: 6rem;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.div-b-menu.open {
  left: 0;
}

.menu-toggle {
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 1001;
  cursor: pointer;
}

.menu-item {
  display: block;
  color: rgb(0, 0, 0);
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
}

.linkAboutUs {
  display: block;
  color: white;
  text-decoration: none;
  padding: 10px;
}

.linkAboutUs:hover {
  background-color: #ffffff;
}

.img-hamburgher-close {
  right: 15px !important;
  margin-top: 4px;
  position: absolute;
}


.scrolling-text {
  font-family: BebasNeue-Regular;
  line-height: 0.8;
  font-size: 4em;
  margin-top: 5px;
}

.scrolling-text-responsive {
  font-family: BebasNeue-Regular;
  line-height: 0.8;
  margin-top: 10px;
  font-size: 26px;
  overflow: hidden;
  white-space: nowrap;
  color: black;
}

.div-scrolling-text-responsive {
  border: 2px solid black;
  margin-top: 2%;
  border-radius: 25px;
  overflow: hidden;
  margin-left: 5px;
  letter-spacing: 1.2px;
  margin-right: 5px;
}

.container-top-content-1{
  color: black;
}