@font-face {
  font-family: BebasNeue-Regular;
  src: url('../resources/fonts/BebasNeue-Regular.ttf');
}

.horizontally-scrolling-text-container {
  padding: 0px;
  font-family: BebasNeue-Regular;
  font-size: 50px;
  letter-spacing: 3px;
  font-weight: 100;
  margin-top: 3px;
}

.ori-dots {
  margin-right: 5px;
}

.container-Hor {
  background-color: white;
  color: white;
  margin-top: 10px;
  border-radius: 50px;
  margin-right: 4px;
  margin-left: 0.3rem;
  border: 2px solid black;
  color: black;
  display: flex;
  overflow: hidden;
}

.container-logo-ori-navbar {
  background-color: black;
  font-size: 50px;
  z-index: 100;
  font-family: BebasNeue-Regular;
  padding-left: 30px;
  display: flex;
  align-items: center;
  border-right: 1px solid white;
}

.horizontally-scrolling-text-container {
  width: 125vw;
  /* Use viewport width to cover the entire screen */
  display: flex;
  /* Use flex display to keep the text within the container */
  align-items: center;
  /* Vertically center the text */
  white-space: nowrap;
  /* Prevent the text from wrapping */
  animation: scrollText 12s linear infinite;
  /* Adjust animation duration as needed */
}

.horizontally-scrolling-text {
  display: inline-block;
}

@keyframes scrollText {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.img-loro-ori {
  width: 30px;
}

.img-loro-ori-text {
  width: 300px;
  height: 30px;
  margin-left: 21px;
  margin-right: 12px;
}

.horizontally-scrolling-text-container-res {
  font-size: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: scrollText 9s linear infinite;
  padding: 0px;
  font-family: BebasNeue-Regular;
  letter-spacing: 3px;
  padding-top: 2px;
}

.container-Hor-res {
  margin-bottom: 5px;
}


@media only screen and (max-width: 900px) {
  .horizontally-scrolling-text-container-res {
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .horizontally-scrolling-text-container-res {
    width: 105%;
  }
}

@media only screen and (max-width: 550px) {
  .horizontally-scrolling-text-container-res {
    width: 130vw;
  }
}