@media screen and (max-width: 1550px) {
    .container-Hor {
        margin-top: 10px;
    }
}

@media screen and (max-width: 1650px) {
    .about-title-r {
        min-width: 250px !important;
    }
}

@media screen and (max-width: 1450px) {
    .about-title-r {
        min-width: 230px !important;
    }
}

@media screen and (max-width: 1300px) {

}

@media screen and (max-width: 1200px) {
    .about-title-r {
        min-width: 200px !important;
    }
}

@media screen and (max-width: 1100px) {
    .about-title-r {
        min-width: 180px !important;
    }
}


@media screen and (max-width: 900px) {
    #containerTopPage {
        margin: auto;
    }

    .about-title {
        display: none;
    }

    .moreAlbum {
        width: 29%;
    }

    .infoNoCoverDiv {
        width: 100%;
    }

    .infoNoCoverDivRes {
        width: 100%;
    }

    .ant-table-cell {
        text-align: center !important;
    }

    .infoTopPageAlbums {
        padding-left: 4.5%;
        padding-bottom: 0px;
    }

    .containerTopPageinfoC {
        margin-top: 5px;
    }

    .topPageInfoResC {
        margin-top: 5px;
        margin-bottom: 30px;
    }

    .contRel {
        padding: 0px;
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .containerTopPageinfoC {
        margin-right: 3.5%;
    }

    .topPageInfoResC {
        margin-right: 3.5%;
    }

    .containerTextInfoAlbumRes {
        display: flex !important;
    }

    .ctvHome {
        max-height: 130px;
        padding-top: 1px;
        height: 120px;
    }

    .infoDateTodayRes {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        background-color: black;
        color: white;
        border-radius: 50%;
        width: 20vw;
        height: 20vw;
        max-width: 200px;
        max-height: 200px;
        font-size: 6vw !important;
        min-font-size: 13px;
        margin-top: 2%;
        padding-top: 0px !important;
    }

    .container-Hor {
        background-color: white;
        color: white;
        margin-top: 2% !important;
        border-radius: 50px;
        margin-right: 5px;
        margin-left: 5px;
        display: flex;
        margin-bottom: 5px;
        overflow: hidden;
        white-space: nowrap;
        color: black;
        text-overflow: ellipsis;
        border: 2px solid;
        font-weight: 900;
    }
}

@media screen and (max-width: 700px) {
    .textInfoAlbum1-text3 {
        font-size: 20px !important;
    }

    .moreAlbum {
        width: 25%;
    }

    .infoNoCoverDiv {
        width: 100%;
    }

    .infoNoCoverDivRes {
        width: 100%;
    }

    .ant-table-cell {
        padding: 2px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .infoTopPageAlbums {
        font-weight: 800;
        font-size: 28px;
    }

    .titleDecade {
        margin-bottom: 20px;
        font-size: 33px;
        margin-left: 1%;
        margin-right: 1%;
        background-color: black;
        color: white;
        padding: 20px;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        padding-left: 20px;
    }

    .tableNoCoverUnique {
        margin-right: 0px;
        margin-top: 5px;
    }

    .ctvHome {
        max-height: 120px;
    }
}

@media screen and (max-width: 550px) {
    .textInfoAlbum1-text3 {
        font-size: 17px !important;
    }

    .showNoCoverButton {
        font-size: 14px;
    }

    .infoNoCoverDiv {
        width: 100%;
    }

    .infoNoCoverDivRes {
        width: 100%;
    }

    .ant-table-cell {
        padding: 2px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .titleDecade {
        margin-bottom: 20px;
        font-size: 28px;
        margin-left: 1%;
        margin-right: 1%;
        background-color: black;
        color: white;
        padding: 20px;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        padding-left: 20px;
    }

    .moreAlbum {
        width: 40%;
    }

    .infoDateTodayRes {
        font-size: 28px;
    }

    .ctvHome {
        max-height: 110px;
        padding-top: 0px;
        height: 80px;
    }
}

@media screen and (max-width: 485px) {
    .textInfoAlbum1-text3 {
        font-size: 17px !important;
    }

    .showNoCoverButton {
        font-size: 14px;
    }

    .ant-table-cell {
        padding: 2px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        max-width: 190px !important;
    }

    .titleDecade {
        margin-bottom: 20px;
        font-size: 26px;
        margin-left: 1%;
        margin-right: 1%;
        background-color: black;
        color: white;
        padding: 10px;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        padding-left: 20px;
    }

    .ctvHome {
        max-height: 100px;
        height: 60px;
    }
}

@media screen and (max-width: 430px) {
    .textInfoAlbum1-text3 {
        font-size: 17px !important;
    }

    .showNoCoverButton {
        font-size: 14px;
    }
}

@media screen and (max-width: 400px) {
    .textInfoAlbum1-text3 {
        font-size: 17px !important;
    }

    .showNoCoverButton {
        font-size: 14px;
    }

    .ant-table-cell {
        padding: 2px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .titleDecade {
        margin-bottom: 15px;
        font-size: 24px;
        margin-left: 1%;
        margin-right: 1%;
        background-color: black;
        color: white;
        padding: 10px;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        padding-left: 20px;
    }
    .img-loro-ori-text-responsive {
        width: 230px !important;
    }
}

@media screen and (max-width: 300) {
    .img-loro-ori-text-responsive {
        width: 210px !important;
    }
}

.img-loro-ori-text-responsive {
    padding: 4px;
    width: 61%;
}

.img-logo-ori-responsive {
    padding: 6px;
}