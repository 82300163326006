.footer {
  height: 100%;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.footerRotd {
  height: 100%;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
}

.itemsColFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 22px;
}

.itemsColFooterRotd {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-size: 23px;
}

.fooCont {
  border-top: 0.8px solid black;
  background-color: white;
}

.fooContRotd {
  border-top: 0.8px solid black;
  background-color: black;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.footerResponsive {
  background-color: black;
  position: relative;
  left: 0;
  bottom: 0px;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  padding: 30px;
  display: flex;
  justify-content: center;
}

body {
  height: 100%;
  width: 100%;
}

.containerFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.main-content {
  flex: 1;
}

.containerAnimVinylLoadApp {
  width: 65%;
  margin: 0 auto;
  margin-top: 15%;
}

#mainLoaderApp {
  display: grid;
  align-items: center;
  justify-content: center;
}

.headroom {
  top: 0;
  left: 0;
  right: 0;
  zIndex: 1;
}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}

.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}

.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}

.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}

.fooItems {
  text-align: center;
  border-bottom-left-radius: 8px;
  margin-bottom: 12px;
}

.fooImg {
  width: 30px;
  margin-left: 10px;
}

.fooIgImg {
  width: 55px;
  margin-left: 2px;
}

.fooSpotifyImg {
  width: 38px;
}

.fooSpotifyImgRotd {
  width: 29px;
}

.fooAboutImg {
  width: 22px;
}

.fooImgLogo {
  width: 14%;
  margin-top: 35px;
  margin-bottom: 35px;
  min-width: 45px;
}

.fooImgLogoCtv {
  width: 50%;
  margin-right: 20px;
}

.fooLogo1 {
  text-align: center;
}

/* responsive footer */
.fooItemsRes {
  text-align: center;
  border-bottom-left-radius: 8px;
  margin-bottom: 12px;
}

.fooImgRes {
  width: 30px;
}

.fooIgImgRes {
  width: 47px;
}

.fooSpotifyImgRes {
  width: 24px;
}

.fooAboutImgRes {
  width: 22px;
}

.fooImgLogoRes {
  width: 30%;
}

.fooLogo1Res {
  text-align: center;
}

.itemsResColFooter {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


.containerAnimVinylLoadApp {
  margin-bottom: 150px;
}

.containerAnimatedLogoIMG {
  width: 40%;
  max-width: 1300px;
}

.containerAnimatedLogo {
  max-width: 100%;
}

.containerLoaderApp {
  display: flex;
  justify-content: center;
}


.menu {
  position: fixed;
  transform: translateY(-100%);
  transition: transform 0.2s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  color: black;
  list-style: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.showMenu {
  transform: translateY(0);
}

@media screen and (max-width: 700px) {
  .containerAnimatedLogoIMG {
    width: 60%;
    max-width: 700px;
  }
  .containerLoaderApp {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 600px) {
  .containerAnimatedLogoIMG {
    width: 60%;
    max-width: 700px;
  }
  .containerLoaderApp {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 450px) {
  .fooImgLogoRes {
    width: 34%;
  }

  .containerAnimatedLogoIMG {
    width: 86%;
    max-width: 400px;
  }
}