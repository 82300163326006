@font-face {
    font-family: Anton;
    src: url('../resources/fonts/Anton-Regular.ttf');
}

@font-face {
    font-family: BebasNeue-Regular;
    src: url('../resources/fonts/BebasNeue-Regular.ttf');
}

a {
    color: #ffffff;
}

.containerMenuBarItems {
    font-family: 'Courier New', monospace !important;
    /* font-size: 18px; */
    /* border-radius: 45px 100px / 120px; */
    /* padding: 9px; */
}

.vl {
    border-bottom: 2.5px solid rgb(0, 0, 0);
    width: 82%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: none;
}

.selectedRecordOfTheDay {
    margin-top: 2px;
}

.selectedContact {
    width: 100px;
}

.selectedMusicGraphics {
    width: 110px;
}

.selectedHome {
    width: 82%;
}

.containerAnimVortex {
    height: 31px;
    width: 61px;
    /* margin-bottom: 14px; */
    margin-top: 9px;
}

.containerAnimNote {
    width: 40px;
    margin-left: 37px;
}

.containerNavItemsFlex {
    display: flex;
    align-items: center;
}

.imgNavBarRecord {
    width: 100%;
    height: auto;
    max-width: 600px;
}

.menuBarApp {
    display: flex;
    max-height: 174px;
    flex-direction: column;
}

.ROTDMenuBar {
    margin-top: 10px;
    /* max-width: 1040px; */
    max-height: 200px;
    width: 100%;
}

#widthPaheAll {
    display: none;
}

.headroom {
    z-index: 80 !important;
}

/* .rotdDiv {
    font-family: BebasNeue-Regular;
} */


.menuNavBar {
    /* position: fixed; */
    /* transform: translateY(-100%); */
    /* transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s; */
    /* top: 0; */
    /* left: 0; */
    /* right: 0; */
    /* bottom: 0;  */
    z-index: 99;
    background: white;
    color: black;
    list-style: none;
    display: none;
    /* padding-top: 4rem; */
    /* height: 100%; */
    margin-bottom: 0px;
}

.showMenuBar {
    transform: translateY(100);
}


.iconBurgherBarOpen {
    width: 40px;
    height: 40px;
    margin-top: 8px;
    margin-left: 15px;
    transition-duration: 0.5s;
}

.iconRotate:active {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}

.containerMenuNavBar {
    background-color: black;
    margin-bottom: 0px;
    display: flex;
    font-family: BebasNeue-Regular;
    background-color: white;
    font-size: 45px;
    border-bottom: 1px solid black;
    /* transition-duration: 0.4s; */
}

.navBarItems {
    text-align: center;
    color: black;
    margin-bottom: 0px;
}

.divItemsNavbar {
    border-left: 0.5px solid black;
    border-right: 0.5px solid black;
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
}

.logoMenuBarFinalWrite {
    width: 45%;
    margin-left: 15px;
    /* max-width: 110px; */
}

.logoMenuBarFinal {
    width: 31%;
    /* max-width: 110px; */
}

.setContentFirstPartNavBar {
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    height: 100%;
}

.divItemsNavbarSet {
    background-color: black;
    color: white;
    transition-duration: 0.4s;
    border: 1px solid white;
}

.ctnDiscoROTD {
    width: 72px;
}

.ctnEqROTD {
    width: 80px;
}

.contNavBar1 {
    max-height: 154px;
    height: 150px;
}

.contNavBar2 {
    height: 150px;
}

.ROTDMenuBar {
    /* max-width: 1040px; */
    /* height: 146px; */
    width: 100%;
}

.setContentFirstPartNavBar {
    width: 250px;
}

.contNavBar1 {
    width: 250px;
}

.todayNavBarImg {
    width: 65%;
    max-width: 170px;
    border-bottom: 2px solid white;
    margin: bottom;
    margin-top: 22px;
    padding-bottom: 13px;
}

.containerDayNavBar {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    max-height: 154px;
    display: inline-block;
    text-align: center;
}

.containerTodayNavBar {
    width: 100%;
}

.containerScrollDayNavBar {
    width: 100%;
}

.textNavBarDate {
    font-family: BebasNeue-Regular;
    font-size: 45px;
}

@media screen and (max-width: 1550px) {
    .ROTDMenuBar {
        padding: 7px;
    }
}

@media screen and (max-width: 1450px) {
    .contTwoLogosNavBar {
        max-height: 139.5px;
    }

    .contNavBar1 {
        width: 230px;
        height: 140px;
    }

    .setContentFirstPartNavBar {
        width: 230px;
    }

    .ROTDMenuBar {
        padding: 10px;
        margin-top: 20px;
    }

    .setDivResize1 {
        height: 140px;
    }

    .setDivResize3 {
        height: 140px !important;
    }

    .menuBarApp {
        max-height: 140px;
    }
}

@media screen and (max-width: 1300px) {
    .textNavBarDate {
        font-family: BebasNeue-Regular;
        font-size: 43px;
    }

    .todayNavBarImg {
        width: 60%;
        max-width: 170px;
        border-bottom: 2px solid white;
        margin: bottom;
        margin-top: 24px;
        padding-bottom: 13px;
    }
}


@media screen and (max-width: 1200px) {
    .contNavBar1 {
        width: 200px;
    }

    .setContentFirstPartNavBar {
        width: 200px;
    }

    .ROTDMenuBar {
        padding: 10px;
    }

    .setDivResize1 {
        height: 130px;
    }

    .setDivResize3 {
        height: 130px !important;
    }

    .menuBarApp {
        max-height: 130px;
    }

    .contNavBar1 {
        height: 130px;
    }
}

@media screen and (max-width: 1100px) {
    .contNavBar1 {
        width: 180px;
    }

    .setContentFirstPartNavBar {
        width: 180px;
    }
}


.recordOfTheDayBigTitle {
    font-family: Anton;
    color: black;
    font-size: 15.5vw;
    margin-top: 1.5%;
    line-height: 1;
}


@keyframes blinkerArrow {
    40% {
        opacity: 0;
    }
}


.img-arrow-responsive {
    width: 20px;
    animation: blinkerArrow 1.3s linear infinite;
}