@font-face {
  font-family: BebasNeue-Regular;
  src: url('../resources/fonts/BebasNeue-Regular.ttf');
}

.containerHome {
  font-family: 'Courier New', monospace !important;
}

.bm-item-list {
  padding: 0px;
}

.bm-menu {
  background: #ffffff;
  border-color: black;
  border: solid 1px;
  border-top: none;
}

.itemHome {
  color: black;
  margin-left: 16px;
}

.byGenres {
  margin-top: 25px;
}

/* per nascondere il bottone di chiusura del menu */
.bm-cross-button {
  height: 24px;
  width: 24px;
}



.itemHome {
  /* margin-bottom: 20px;
  width: 80%; */
  margin-bottom: 10px;
  width: 95%;
  font-family: 'BebasNeue-Regular';
  font-size: 27px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.topMenuTitle {
  border-bottom: solid 0.2px black;
  /* border-radius: 0 25px 25px 0;F */
  background-color: black;
  color: white;
  padding: 5px;
  padding-left: 20px;
  font-family: BebasNeue-Regular;
  border-top-right-radius: 31px;
}

.containerArticles {
  margin: auto;
}

.bm-menu-wrap {
  width: 100% !important;
}

#containerSingleArticle {
  border: solid 0.1px black;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;

}

.containerTextArticle {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.imgArticle {
  width: auto;
  height: 340px;
}

.titleSingleArticle {
  font-size: 30px;
  padding-left: 20px;
  border-bottom: solid 1px black;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
}

.textSingleArticle {
  font-size: 18px;
  padding-top: 29px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  word-break: break-word;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-overflow: ellipsis;
  max-height: 373px;
}

.txtArticle {
  overflow: scroll;
  word-break: break-word;
  justify-content: space-between;
  flex-direction: column;
  text-overflow: ellipsis;
  max-height: 248px;
  letter-spacing: 1px;
}

.containerNavIcons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 40px;
}

.img-TextArticle {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.oddArticle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.oddTitleArticle {
  display: flex;
  justify-content: flex-start;
  padding-right: 25px;
}

.infoArticleAuthor {
  display: grid;
  font-size: 14px;
}

.infoArticleAuthorOdd {
  justify-content: start;
}

.infoArticleAuthorEven {
  justify-content: end;
}

.textSingleArticleEven {
  align-items: flex-end;
}

.infoTopPageArticles {
  font-weight: 800;
  font-size: 35px;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 30px;
  font-family: BebasNeue-Regular;
}

#cancelButtonModal {
  display: none;
}

.imgArticleModal {
  margin-bottom: 25px;
}

/* ---------------modal article css----------------- */
.ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
  background-color: black;
  border: 1px solid black;
}

.ant-modal-header {
  border: 0.8px solid black;
}

.ant-modal-title {
  text-align: center;
  font-size: 25px;
}

.ant-modal-body {
  border: 0.8px solid black;
  border-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  word-break: break-word;
}

.ant-modal-footer {
  border: 0.8px solid black;
  border-top: 0px;
}

.ant-modal-content {
  font-family: 'Courier New', monospace !important;
}

/* -----------scroll bar custom----------- */
.txtArticle::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.txtArticle::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.txtArticle::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
  border-radius: 25px;
}

/* Handle on hover */
.txtArticle::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

.containerDiscoAnimLateralMenu {
  width: 70px;
}

#widthPaheAll {
  display: none;
}

.mobileSetupBurgherMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}


/* per lo scorrimento del testo */
.targetTextRun {
  animation: flowing 8s linear infinite;
  transform: translateX(100%);
  margin-bottom: 0px;
  font-size: 16px;
  display: flex;
  white-space: nowrap;
  font-size: 21px;
  font-family: BebasNeue-Regular;
}

@keyframes flowing {
  100% {
    transform: translateX(-100%);
  }
}

.containerTextRun {
  overflow: hidden;
}

ol,
ul {
  padding-left: 0px;
}

.menuItem {
  display: block;
  color: rgb(0, 0, 0);
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.menuItem:hover {
  text-decoration: underline;
}

.hamburger {
  z-index: 100;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.closeIcon {
  display: none;
}

.menu {
  position: fixed;
  transform: translateY(-100%);
  transition: transform 0.2s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: white;
  color: black;
  list-style: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.showMenu {
  transform: translateY(0);
}

.itemMenuResponsive {
  background-color: #000000;
  color: white;
  font-size: 30px;
  font-family: BebasNeue-Regular;
}

.containerIconCloseAndArticleText {
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.itemMenuResponsiveVibez {
  background-color: #000000;
  color: white;
  font-size: 30px;
  font-family: BebasNeue-Regular;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  display: none;
}

.ctvHome {
  width: 100%;
  padding-left: 3px;
  padding-right: 3px;
}

.ctvhomecontainer {
  border-bottom: 0.8px solid black;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 52px;
}


#scroll-container {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  font-size: 21px;
  font-family: BebasNeue-Regular;
}

#scroll-text {
  text-align: left;

  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 12s linear infinite;
  -webkit-animation: my-animation 12s linear infinite;
  animation: my-animation 12s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}


.about-title {
  top: 0px !important;
}

/* (A) OUTER CONTAINER */
.hwrap {
  width: 100%;
  overflow: hidden;
  font-size: 21px;
  font-family: BebasNeue-Regular;
  padding-left: 15px;
  white-space: nowrap;
}

/* (B) MIDDLE CONTAINER - FLEX LAYOUT */
.hmove {
  display: flex;
  position: relative;
  top: 0;
  right: 0;
}

/* (C) SLIDES - FORCE INTO ONE LONG HORIZONTAL ROW */
.hslide {
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
}

/* (D) SLIDE ANIMATION */
@keyframes slideh {

  /* (D0) THE IDEA - USE KEYFRAMES TO SHIFT SLIDES * */
  0% {
    right: 0;
  }

  33% {
    right: 100%;
  }

  66% {
    right: 200%;
  }

  100% {
    right: 0;
  }
}

.hmove {
  animation: slideh linear 25s infinite;
}

.linkAboutUs {
  margin-bottom: 20px;
  margin-top: 10px;
}

.topMenuTitleVibes {
  display: none;
}

#contactUs {
  display: none;
}