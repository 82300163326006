@font-face { font-family: BebasNeue-Regular; 
    src: url('../resources/fonts/BebasNeue-Regular.ttf'); 
}

.containerTopPageinfoC {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.topPageInfo2C{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.allReleaseDiv{
    font-size: 63px;
    font-weight: bold;
    font-family: BebasNeue-Regular;
}

.topPageInfoResC{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 30px;
    margin-left: 3.5%;
    flex-wrap: nowrap;
  }

  @media screen and (max-width: 1400px) {
    .allReleaseDiv{
        font-size: 55px;
    }
  }

  @media screen and (max-width: 1300px) {
    .allReleaseDiv{
        font-size: 50px;
    }
  }