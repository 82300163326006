.modal {
    display: none; /* Hidden by default */
    width: 400;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.817); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .Mmodal-content {
    background-color: none !important;
    margin: auto;
    padding: 20px;
    /* border: 1px solid #888; */
    width: 50%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }







  /* modal album----------------------- */
  