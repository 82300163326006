@media screen and (max-width: 1520px) {
    .itemsColFooterRotd{
        font-size: 21px;
    }
    .fooImgLogo {
        margin-top: 33px;
        margin-bottom: 33px;
    }
}

@media screen and (max-width: 1420px) {
    .itemsColFooterRotd{
        font-size: 21px;
    }
    .itemsColFooter{
        font-size: 21px;
    }
    .fooImgLogo {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .fooImgLogoCtv {
        width: 52%;
        margin-right: 20px;
    }
}

@media screen and (max-width: 1320px) {
    .itemsColFooterRotd{
        font-size: 20px;
    }
    .itemsColFooter{
        font-size: 20px;
    }
    .fooImgLogo {
        margin-top: 28px;
        margin-bottom: 28px;
    }
    .fooImgLogoCtv {
        width: 54%;
        margin-right: 20px;
    }

}

@media screen and (max-width: 1320px) {
    .itemsColFooterRotd{
        font-size: 19px;
    }
    .itemsColFooter{
        font-size: 19px;
    }
    .fooImgLogo {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}