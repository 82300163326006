@media screen and (max-width: 1600px) {

}

@media screen and (max-width: 1430px) {
    .about-pages {
        /* width: 80%; */
    }
}

@media screen and (max-width: 1320px) {
    .about-pages {
        /* width: 75%; */
        /* margin-left: 1em; */

    }
    .about-title{
        min-width: 150px;
    }
    .imgArticle{
        /* max-width: 337px;
        max-height: 337px; */
        /* width: 337px;
        height: 337px; */
        width: auto;
        height: 317px;
    }
}
@media screen and (max-width: 1290px) {
    .about-pages {
        /* width: 75%; */
        /* margin-left: 1em; */

    }
    .about-title{
        /* font-size: 14px; */
        /* width: 130px!important; */
    }
    .imgArticle {
        /* max-width: 275px;
        max-height: 275px; */
        /* height: 275px;
        width: 275px; */
        width: auto;
        height: 275px;
    }
    .txtArticle{
        max-height: 192px;
    }
    .itemHome{
        margin-left: 13px;
        width: 96%;
    }
}


@media screen and (max-width: 1090px) {
    .about-pages {
        /* width: 75%; */
        /* margin-left: 1em; */

    }
    .about-title{
        /* min-width: 135px; */
    }
    .imgArticle {
        /* width: 250px;
        height: 250px; */
        width: auto;
        height: 250px;
    }
    .txtArticle{
        max-height: 159px;
    }
    .titleSingleArticle {
        font-size: 25px;
    }
    .topMenuTitle {  
        font-size: 25px;
    }
    .infoTopPageArticles {
        font-size: 30px;
    }
    .containerNavIcons {
        padding: 36px;
    }
}

@media screen and (max-width: 900px) {
    .textSingleArticle {
        font-size: 14px;
    }
    .about-pages{
        width: 100%;
        margin-top: 0px;
    }
    .about{
        display: inline-block;
        width: 100%;
    }
    .txtArticle{
        overflow: overlay;
    }
    /* .titleSingleArticle{
        background-color: white;
        color: black;
    } */
    .infoTopPageArticles{
        /* z-index: -1; */
        padding-left: 20px;
        padding-bottom: 0px;
    }
    /* .imgArticle{
        z-index: 0;
    } */
}

@media screen and (max-width: 700px) {
    .textSingleArticle {
        font-size: 14px;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom:10px;
    }
    .imgArticle {
        /* max-width: 200px;
        max-height: 200px; */
        /* width: 200px;
        height: 200px; */
        width: auto;
        height: 200px;
    }
    .txtArticle{
        max-height: 138px;
        letter-spacing: 0px;
    }
    .infoArticleAuthor {
        font-size: 11px;
    }
    .titleSingleArticle {
        font-size: 22px;
    }
    .infoTopPageArticles {
        font-weight: 800;
        font-size: 29px;
        /* padding: 10px;
        padding-top: 0px;
        padding-bottom: 20px; */
    }
    .iconBurgherOpen{
        width: 56%;
    }
    /* .containerBurgherIcon{
        margin-right: 3%;
    } */
}
@media screen and (max-width: 500px) {
    .textSingleArticle {
        font-size: 13px;
    }
    .imgArticle {
        /* max-width: 150px;
        max-height: 150px; */
        /* width: 150px;
        height: 150px; */
        width: auto;
        height: 150px;
    }
    .txtArticle{
        max-height: 120px;
        letter-spacing: 0px;
    }
    .titleSingleArticle {
        font-size: 17px;
    }
    .setArticleFontTitle{
        font-size: 16px;
    }
    .infoTopPageArticles {
        font-size: 27px;
    }
}

@media screen and (max-width: 580px) {
    
}


@media screen and (max-width: 350px) {
    .hwrap{
        font-size: 19px;
    }
}

@media screen and (max-width: 320px) {
    .hwrap{
        font-size: 18px;
    }
}