.containerGridAlbum {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-areas: "a b c";
    margin-top: 30px;
}

.containerAnimSquare {
    grid-area: b;
}

.containerFirstInfoAlbum {
    display: grid;
    justify-content: end;
    grid-area: a;
    align-items: start;
}

.containerSecondInfoAlbum {
    display: grid;
    justify-content: start;
    grid-area: c;
    align-items: start;
    align-content: center;
}

.textInfoAlbum1 {
    font-size: 13px;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 210px;
    font-family: 'Courier New', monospace !important;
}


.about {
    justify-content: center;
}

.textInfoAlbum2 {
    font-size: 13px;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 210px;
    font-family: 'Courier New', monospace !important;
}

@keyframes typing {
    from {
        width: 0%
    }

    to {
        width: 100%
    }
}

@keyframes blink {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: rgb(0, 0, 0);
    }
}
@keyframes background-fade {
    99.9% {
        border-color: rgb(0, 0, 0);
    }

    100% {
        border-color: rgb(254, 254, 254);
    }
}
.fooImgLogoInfo {
    width: 100%;
}
.fooItemsInfo {
    text-align: center;
}

.textInfoAlbum2 {
    overflow: auto;
}