@media screen and (max-width: 900px) {
    .containerAnimDisco{
        width: 60%;
    }
    .containerAnim{
        width: 25%;
        border-bottom: 1.2px solid black;
    }
    .divDiscoAnim{
        display: flex;
        flex-direction: row-reverse;
    }
    .responsive-img-principalLogo {
        width: 100%;
        height: auto;
        max-height: 125px;
        padding: 0px;
    }
}

@media screen and (max-width: 550px) {
    .containerAnimDisco{
        width: 50%;
    }
    .containerAnim{
        width: 50%;
    }
    .responsive-img-principalLogo {
        width: 100%;
        height: 20px;
        max-height: 125px;
        padding: 0px;
    }
}