@font-face {
    font-family: BebasNeue-Regular;
    src: url('../resources/fonts/BebasNeue-Regular.ttf');
  }

body{
    margin: 0 !important;
}

.about{
    font-family: BebasNeue-Regular !important;
    height: auto;
    display: flex;
    /* flex-wrap: nowrap;
    justify-content: space-around; */
}

/* .about-title{
    width: 15%;
    min-width: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    border-right: solid 2px black;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: 17px;
    align-items: stretch;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
} */

.about-pages {
    width: 85%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-top: 30px;
    /* margin-bottom: 50px; */
}


/* footer{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
} */